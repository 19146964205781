import User from "@/core/services/car/NEW/User"

export async function useGetSalesAgents(payload) {
    const user = JSON.parse(window.localStorage.getItem('user'))

    if(payload.checkIsTransfer != 'customer-list') {
        payload.isTransfer = payload.agent == user.id ? false : true
    }
    delete payload.agent
    
    return await User.getDomSalesAgents(payload)
}

export function useUser() {
    const user = JSON.parse(window.localStorage.getItem('user'))
    if(!user) return {}
    const role_id = user.role_id
    const user_id = user.id
    const email = user.email
    const policy_agent_id = user.policy_agent_id

    return {
        user,
        role_id,
        user_id,
        email,
        policy_agent_id
    }
}

function pagesDefault() {
    return {
        pLeadsAndTasks :  true ,
        pUploadPolicy :  true,
        pCUstomerDetails :  true,
        pAmendInvoice :  true,
        pAssignedPolicy :  true,
        pCompletedPolicy :  true,
        pCancellationRequest :  true,
        pCancelled :  true,
        pRefundClosed :  true,
        pApproveViewCancellation : true,
        pApproveViewRefund :  true,
        pApprovedInvoice :  true,
        pDeclinedInvoice :  true
    }
}

export function useRoleBasedConditions() {
    const user = JSON.parse(window.localStorage.getItem('user'))

    if(!user) return defaultPagesValue()
    const role_id = user.role_id

    if(!role_id) return defaultPagesValue()

    const customerBack = ![7, 11].includes(role_id)
    const salesAgentRequired = ![1, 7, 11, 15].includes(role_id)
    const AgentDefaultRole = [2, 9, 13].includes(role_id)
    const showDeleteAction = [1, 4, 14].includes(role_id)
    const showDeleteSalesAgentAction = [2, 9, 13].includes(role_id)
    const showLogAction = [1, 6, 15].includes(role_id)
    const policyLogTab = [1, 9, 14, 15].includes(role_id)
    const showRequestQuote = [1, 2, 9, 13, 14, 15].includes(role_id)
    const setRequestQuote = [1, 14].includes(role_id)
    const showView = [4, 1, 14].includes(role_id)
    const switchApp = [1, 9, 13].includes(role_id)
    const adminManager = [1, 9, 14, 15].includes(role_id)

    // pages
    const pLeadsAndTasks = [1, 9, 13, 2, 14, 15].includes(role_id)
    const pUploadPolicy = [1, 9, 13, 2, 4, 6, 14, 15].includes(role_id)
    const pCUstomerDetails = [1, 9, 13, 2, 6, 7, 11, 14, 15].includes(role_id)
    const pAmendInvoice = [1, 9, 13, 2].includes(role_id)
    const pAssignedPolicy = [1, 9, 13, 2, 4, 6, 14, 15].includes(role_id)
    const pCompletedPolicy = [1, 9, 13, 2, 4, 6, 14, 15].includes(role_id)
    const pCancellationRequest = [1, 9, 4, 15].includes(role_id)
    const pCancelled = [1, 9, 13, 2, 4, 6, 14, 15].includes(role_id)
    const pRefundClosed = [1, 9, 13, 2, 4, 6, 14, 15].includes(role_id)
    const pApproveViewCancellation = [1, 9, 6, 15].includes(role_id)
    const pApproveViewRefund = [1, 9, 6, 15].includes(role_id)
    const pApprovedInvoice = [1, 9, 13, 2, 6, 14, 15].includes(role_id)
    const pDeclinedInvoice = [1, 9, 13, 2, 6, 14, 15].includes(role_id)
    const pInvoiceForApproval = [1, 9, 13, 2, 6, 15].includes(role_id)
    const pMarketingCampaigns = [1, 11].includes(role_id)
    const pMarketingSpend = [1, 11].includes(role_id)
    const pMarketingReport = [1, 11, 14, 15].includes(role_id)
    const pPolicyAssignedVsCompleted = [1, 9, 6, 4, 14, 15].includes(role_id)
    const pAgentSales = [1, 9, 6, 14, 15].includes(role_id)
    const pAccountMaster = [1, 6, 14, 15].includes(role_id)
    const pDiscrepancy = [1, 9, 6, 14, 15].includes(role_id)
    const pSkyeLoginInfo = [1, 9, 14, 15].includes(role_id)
    const pDashboardDetailReport = [1, 9, 11, 6, 14, 15].includes(role_id)
    const pLeadBySourceReport = [1, 9, 11, 6, 14, 15].includes(role_id)
    const pClosedLeadsReport = [1, 9, 11, 14, 15, 6].includes(role_id)
    const pDeletedLeadsReport = [1, 14, 9, 11, 15, 6].includes(role_id)
    const pProductionReport = [1, 6, 14].includes(role_id)
    const pAdminReport = [1, 9, 6, 11, 14, 15].includes(role_id)
    const allRoles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10 , 11, 12, 13, 14, 15].includes(role_id)
    const pSalesTrackingReport = [1, 6, 9, 14, 15].includes(role_id)
    const pAgentLeadActivityReport = [1, 6, 9, 13, 14, 15].includes(role_id)

    // leads and tasks
    // firstlayer
    const ltAgentDropDown = [1, 13, 9, 14, 15].includes(role_id)
    const pFirstAgentUnderwriter = [1, 6,9].includes(role_id)
    const cdAddBike = [4, 6, 7, 11].includes(role_id);
    const cdTransfer = [4, 6, 7, 11].includes(role_id);
    const iFirstRunScheduler = [1, 6].includes(role_id)
    const qlSalesAgent = [1, 7].includes(role_id)
    const customerOpenMerge = ![7, 11, 15].includes(role_id)
    const editWhatsappOtherInfo = ![7, 11, 15].includes(role_id)

    // dashboard
    const adminDashboard = [1, 14].includes(role_id)
    const SASMTLDashboard = [2, 9, 13].includes(role_id)
    const omDashboard = [15].includes(role_id)
    const SMTLDashboard = [9, 13].includes(role_id)
    const MarketingAdminDashboard = [7, 11].includes(role_id)

    // report
    const ApprovedInvoicedSATLReport = [2, 13].includes(role_id)
    const ApprovedInvoicedAAMQReport = [1, 9, 6, 14].includes(role_id)
    const ApprovedInvoiceASMReport = [1, 9].includes(role_id)
    return {
        pAdminReport,
        omDashboard,
        editWhatsappOtherInfo,
        customerOpenMerge,
        pSalesTrackingReport,
        adminManager,
        customerBack,
        salesAgentRequired,
        AgentDefaultRole,
        showDeleteAction,
        showDeleteSalesAgentAction,
        showLogAction,
        policyLogTab,
        showRequestQuote,
        setRequestQuote,
        showView,
        pUploadPolicy,
        pLeadsAndTasks,
        ltAgentDropDown,
        pCUstomerDetails,
        pAmendInvoice,
        pAssignedPolicy,
        pCompletedPolicy,
        pFirstAgentUnderwriter,
        switchApp,
        pCancellationRequest,
        pCancelled,
        pRefundClosed,
        pApproveViewCancellation,
        pApproveViewRefund,
        cdAddBike,
        cdTransfer,
        pMarketingCampaigns,
        pMarketingSpend,
        pMarketingReport,
        pApprovedInvoice,
        pDeclinedInvoice,
        pInvoiceForApproval,
        pPolicyAssignedVsCompleted,
        pAgentSales,
        pAccountMaster,
        pDiscrepancy,
        pSkyeLoginInfo,
        pDashboardDetailReport,
        pLeadBySourceReport,
        pClosedLeadsReport,
        pDeletedLeadsReport,
        pProductionReport,
        allRoles,
        iFirstRunScheduler,
        qlSalesAgent,
        adminDashboard,
        SASMTLDashboard,
        SMTLDashboard,
        MarketingAdminDashboard,
        ApprovedInvoicedSATLReport,
        ApprovedInvoicedAAMQReport,
        ApprovedInvoiceASMReport,
        pAgentLeadActivityReport
    }

}

function defaultPagesValue() {
    return {
        pLeadsAndTasks :  true ,
        pUploadPolicy :  true,
        pCUstomerDetails :  true,
        pAmendInvoice :  true,
        pAssignedPolicy :  true,
        pCompletedPolicy :  true,
        pCancellationRequest :  true,
        pCancelled :  true,
        pRefundClosed :  true,
        pApproveViewCancellation : true,
        pApproveViewRefund :  true,
        pApprovedInvoice :  true,
        pDeclinedInvoice :  true,
        pMarketingCampaigns :  true,
        pMarketingSpend :  true,
        pMarketingReport :  true
    }
}
/**
 *  1	Admin
    2	Sales Agent
    3	Policy User
    4	Underwriter
    5	Document Chaser
    6	Accountant
    7	Marketing Agent
    8	Offline Sales Agent
    9	Sales Manager
    10	Online Marketing
    11	Marketing Admin
    12	Sales Admin
    13	Team Leader
    14 QCA
    15 Operation Manager
 */