
import { computed, defineComponent, onMounted, provide, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { number } from "yup/lib/locale";
import { useRoute } from "vue-router";
import browser from './browser.js'
import { statusModal } from '@/store/stateless/store';
import BrowserModal from '@/views/crafted/modals/extras/Browser.vue'
import mainSocket from "./main-socket";
import OnlinePaymentInfoModal from '@/components/modals/general/OnlinePaymentInfoModal.vue';
import {setBodyPositionFixed} from "@/store/stateless/store";
import UserService from "@/core/services/UserService";
import BankDetailsModal from "@/components/modals/forms/BankDetailsModal.vue";
import MasterService from '@/core/services/car/MasterService';
import LocalStorageServices from "@/core/services/LocalStorageServices";
import { ElNotification } from "element-plus";
import { useUser } from '@/store/composable/User'


export default defineComponent({
name: "app",

components: {
  BrowserModal,
  OnlinePaymentInfoModal,
  BankDetailsModal
},

setup() {
  const store = useStore();
  const router = useRoute()

  let countdown = ref(60);
  const datetime = ref<any>(null);
  const respo = ref<any>([]);
  let userObj: any = {};
  const resetPassword = ref()

  const { user_id } = useUser()

  const user = computed(() => {
    userObj = LocalStorageServices.getUser();
    if (userObj == null) {
      userObj = {};
      return userObj;
    }
    return JSON.parse(userObj);
  });

  onMounted(async() => {
    // window.localStorage.removeItem("user");
    // console.log(`socket started at port ${process.env.VUE_APP_SOCKET_PORT}`)

    // const ref_time = window.localStorage.getItem('ref_time')
    // alert(ref_time)
    // if(ref_time) {
    //   countdown.value = parseInt(refTime)
    // }

      const currentUser = await store.dispatch(Actions.VERIFY_AUTH)
      if(currentUser && currentUser.has_password_change == 0) {
        resetPassword.value.open(user.value.email)
      }

      if(Object.keys(user.value).length > 0) {
        var payloadPolicyStatus = {};
        if(router.name == 'report-account-master') {
          payloadPolicyStatus = {
            is_account_master:true
          };
        }
        UserService.getDomSalesAgentsManagerTeamLeader();
        // MasterService.getReasonnReason();
        MasterService.getPolicyStatuses(payloadPolicyStatus);
      }

      if(store.state.AuthModule.isAuthenticated) {
    store.dispatch(Actions.GET_ROUTE_LIST, { type: "car" });
  }

      store.dispatch(Actions.REFRESH_TIME)
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      // store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      // mainSocket.channel("channel").listen("Hello", (msg) => {
      //   alert("im in");
      // });
      const currentUserId = user.value.id;

      mainSocket.channel(`logout-${currentUserId}`)
      .listen('LoginEvent', (data: any) => {
        MasterService.purgeCache()
      })

      // mainSocket.channel(`switchapp-${currentUserId}`)
      // .listen('SwitchApp.SwitchAppEvent', (response: any) => {
        
      //   /**
      //    * Redirecting current active tab to the
      //    * switch app urls other tabs reload the
      //    * same page after clearing the local storage
      //    */
      //   if (!document.hidden) {
      //       window.location.href = response.switch_app_url;
      //   }else{
      //     setTimeout(() => {
      //       window.localStorage.clear();
      //       window.location.reload();
      //     }, 3000);
      //   }
      // })

      mainSocket
        .channel("channel-" + currentUserId)
        .listen("PopupNotification", async(response: any) => {
          //console.log("PopupNotification", response);

        respo.value.push(response)
        if (response.is_popup) {
          let contentHtml =
            await response.url != ""
              ? "<a href='#" + response.url + "'>" + response.content + "</a>"
              : response.content;
          setTimeout( async() => {
            if(datetime.value !== response.dateTime) {
              await respo.value.filter((item) => item.dateTime === response.dateTime)
              await respo.value.forEach(x => {
                popupNotification(contentHtml, x.isNotifStay);
              })

              respo.value = [];
            }
            datetime.value = response.dateTime
            }, 1000);
        }
      });

    // if(router.name != 'sign-in') {
    //   countdown.value = await refreshTime(0);
    //   window.localStorage.setItem('ref_time', countdown.value.toString())
    //   countDownTimer()
    // } else {
    //   countdownToken()
    // }
    
    checkBroweser();
  });

  // const countDown = ref<number>(70)

  // watch(() => 
  // router.name, (value) => {
  //   if(value != 'sign-in') {
  //     countDownTimer();
  //   } 
  //   else {
  //     countdownToken();
  //   } 
  // })

  // const tokenCheckerLocalStorageTime = ref(5);
  // const tokenCheckerLocalStorageTimeSignIn = ref(5);

  // const countdownToken = () => {
  //   // if(tokenCheckerLocalStorageTimeSignIn.value > 0) {
  //         setTimeout(() => {
  //           tokenCheckerLocalStorageTimeSignIn.value -= 1;
  //           countdownToken()
  //         }, 1000);
  //       // }

  //       if(tokenCheckerLocalStorageTimeSignIn.value <= 0) {
  //         const token = window.localStorage.getItem('id_token');
  //         tokenCheckerLocalStorageTimeSignIn.value = 5
  //         countdownToken()
  //         if(token) {
  //           if(router.name == 'sign-in') {
  //             // router.push('/dashboard')
  //             // window.location.reload();
  //           }
  //         }
          
  //       }
  // }

  // const countDownTimer =async() => {
  //   const refresh_token = window.localStorage.getItem('refresh_token') || ''
  //   const ref_time = window.localStorage.getItem('ref_time')
  //   if(refresh_token) {
  //       if(countdown.value > 0) {
  //           setTimeout(() => {
  //             console.log('timerrrr')
  //               countdown.value -= 1
  //               window.localStorage.setItem('ref_time', countdown.value.toString())
  //               countDownTimer();
  //               // console.log(countdown.value)
  //           }, 1000)
  //       }

  //       if(countdown.value <= 0 && ref_time) {
  //             console.log('timeryyy')
  //         // alert('GO')
  //         const token = window.localStorage.getItem('id_token');
  //         if(token) {
  //           const payload = {
  //             refresh_token
  //           }
  //           window.localStorage.removeItem('ref_time')
  //           const response = await store.dispatch(Actions.CHECK_TOKEN, payload)
  //           if(response.status == 200) {
              
  //         countdown.value = await refreshTime(0);
  //         window.localStorage.setItem('ref_time', countdown.value.toString())
  //             await countDownTimer();
  //           }
            
  //         }
  //       }

        
  //       if(tokenCheckerLocalStorageTime.value > 0) {
  //         setTimeout(() => {
  //           tokenCheckerLocalStorageTime.value -= 1;
  //         }, 1000);
  //       }

  //       if(tokenCheckerLocalStorageTime.value <= 0) {
  //         const token = window.localStorage.getItem('id_token');
  //         tokenCheckerLocalStorageTime.value = 5
  //         if(!token) {
  //           store.commit(Mutations.SET_USER, {});
  //           store.commit(Mutations.SET_USER_ACL, {});
  //           // window.localStorage.clear();
  //           // window.location.reload();
  //         }
          
  //       }
        
  //   }
  // }

  // const refTime = store.getters.getRefreshTime

  // const refreshTime = async(value) => {
  //   if(value != 0) {
  //     return value
  //   } 
  //   else {
  //     let expiry = parseInt(window.localStorage.getItem('expires_in') || '3300')
  //     expiry = (expiry / 2) - 300
  //     const ref_time = window.localStorage.getItem('ref_time')
  //     // alert(ref_time) 
  //     if(ref_time) expiry = parseInt(ref_time)
      
  //     return expiry
  //   }
  // }

  
  const checkBroweser =() => {
    var result = browser.getParser(window.navigator.userAgent);

    if(result.parsedResult.browser.name != 'Firefox' && result.parsedResult.browser.name != 'Chrome' && result.parsedResult.browser.name != 'Opera') {
      statusModal.isNotValidBrowser = true
    }
    
  }

  function changeRole() {
    console.log('change')
    window.location.href = '/#/dashboard'
    window.location.reload()
  }

  const popupNotification = (contentHtml, isNotifStay) => {
    ElNotification({
      customClass: isNotifStay? "notification-skye":"notification-success",
      title: "Notification",
      message: contentHtml,
      duration: isNotifStay? 10000:2500,
      type: isNotifStay? "info":"success",
      dangerouslyUseHTMLString: true
    });
  };

  provide('currency', {
    currency: 'AED',
    vat: true
  })
  provide('details', {
    bcc: 'mark@yopmail.com',
    email_template_id: 5,
    aspect: window.devicePixelRatio
  })

  function updateToken(data) {
    window.localStorage.setItem('item_data', JSON.stringify(data))
    if(data.status == 400) {
      store.commit('SESSION_DELETED')
    }

    if(data && data.status == 200) {
      window.localStorage.setItem('refresh_token', data.refresh.refresh_token)
      window.localStorage.setItem('id_token', data.refresh.access_token)
      window.location.reload()
    }  

    
  }

  /**
   * Websocket for switch app reload
   */
     function switchAppReloadWS(data) {
      if(data.switch_app_url){
          if (!document.hidden) {
              /**
               * Only redirect to the switch app url 
               * received from the swithc app api in 
               * the active tab
                 */
                window.location.href = data.switch_app_url;
          }else{
            console.log(data);
                /**
                 * All other tabs we will clear 
                 * the local storage and redirect which will
                 * open the sign in page
                 */
                setTimeout(() => {
                  window.localStorage.clear();
                    window.location.reload();
                }, 3000);
          }
      }
    }
    
  return {
    resetPassword,
    updateToken,
    user_id,
    user,
    // refTime,
    router,
    // countdownToken,
    // countDownTimer,
    // tokenCheckerLocalStorageTime,
    // tokenCheckerLocalStorageTimeSignIn,
    statusModal,
    // refreshTime,
    checkBroweser,
    setBodyPositionFixed,
    countdown,
    switchAppReloadWS,
    changeRole
  }

},
});
