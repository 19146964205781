import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import { useRoleBasedConditions, useUser } from '@/store/composable/User'
import moment from "moment"
import ApiService from "@/core/services/ApiService";
import { customerDetails } from '@/core/types/customers'
import { CustomerTop } from '@/core/types/customers'
import { common } from '@/store/stateless/common'
import { useStore } from 'vuex';

const { AgentDefaultRole } = useRoleBasedConditions()
const { user_id, role_id } = useUser()

export interface CUSTOMER {
    customer: ICustomer
}

interface ICustomer {
    [key: string]: string | number | null
}

@Module
export default class Customer extends VuexModule implements CUSTOMER{
    componentLoad = false
    customerDetail = {} as customerDetails
    leads = []
    existCustomer = null
    isQLDisable = true
    viewTasks = []
    notes = []
    policies = []
    customer = {
        name: '',
        email: '',
        phone_country_code: '+971',
        phone_number: '',
        car_year: 0,
        manufacturer_id: 0,
        model_id: 0,
        trim_level_id: 0,
        is_new: 2,
        car_value: 0,
        first_registration_date: '',
        registration_emirate: 2,
        dob: '',
        nationality: null,
        first_driving_license_country: null,
        driving_experience: 4,
        uae_driving_experience: 4,
        policy_start_date: moment().format('YYYY-MM-DD'),
        claimed_insurance: 2,
        no_claim_certificate: 1,
        claims: null,
        gcc_specification: 1,
        personal_use: 1,
        current_policy_active: 1,
        fully_comprehensive: 1,
        third_party_liability: 2,
        existing_policy_expired: 2,
        driver_name: null,
        agent: AgentDefaultRole ? user_id : null,
        gender: null,
        is_car: 1,
        lead_source: 'skye',
        is_vintage: 0,
        vehicle_type: 1
    } as ICustomer
    customer_edit = {
        customer_id: 0,
        name: '',
        phone_country_code: '',
        phone_number: '',
        confirm_phone_country_code: '',
        confirm_phone_number: '',
        email: '',
        confirm_email: '',
        current_email: '',
        current_phone_number: '',
        other_contact_info: '',
        current_other_contact_info: ''
    }
    amend_invoice = {
        amend_type: 1
    }
    customerActivityLog = []
    customerStatusLog = []
    customerViewActivityLog = []
    documentLogs = []
    policyLogs = []
    emails = []
    manualQuotes = []
    sms = []
    leadActivityLog = []
    leadStatusLog = []
    responses = {}
    insurancePlan = []
    markFlowViewQuotesList = []
    customer_detail_lead = []
    openLead = {}

    // Actions
    @Action
    async['GET_CUSTOMER_DETAILS'] (payload) {
        try {
            if(payload.clear){
                this.context.commit('SET_CUSTOMER_DETAILS', {})
                this.context.commit('SET_CUSTOMER_LEAD_DETAILS', [])
            }
            delete payload.clear

            const response = await ApiService.post('/skye/car-leads', payload)
            this.context.commit('SET_CUSTOMER_DETAILS', response.data.data)
            this.context.commit('SET_CUSTOMER_LEAD_DETAILS', response.data.data)
            
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_CUSTOMER_DETAILS_V2'](payload) {
        try {
            if(payload.clear){
                this.context.commit('SET_CUSTOMER_DETAILS', {})
                this.context.commit('SET_CUSTOMER_LEAD_DETAILS', [])
            }
            delete payload.clear

            // if(payload.pagination){
                const response = await ApiService.post('/skye/car-customer-details', payload)
                
                this.context.commit('SET_CUSTOMER_DETAILS', response.data.data)
                this.context.commit('SET_CUSTOMER_LEAD_DETAILS_2', response.data.data)
            // }
            
            // setTimeout(async () => {
                // payload.pagination = false
                // const response = await ApiService.post('/skye/car-customer-details', payload)
            
                // this.context.commit('SET_CUSTOMER_DETAILS', response.data.data)
                // this.context.commit('SET_CUSTOMER_LEAD_DETAILS_2', response.data.data)
            // }, 500);
            

            return response

        } catch (error) {
            return error
        }
    }
    @Action
    async['GET_CAR_LEAD'](payload) {
        try {
            const scene:any = {
                car_lead_id: payload.lead_id,
                customer_id: payload.customer_id
            }
            this.context.commit('SET_CUSTOMER_OPEN_LEAD_DETAILS', {lead: {}})
            const scenario = await ApiService.post('/skye/car-lead', scene)
            this.context.commit('SET_CUSTOMER_OPEN_LEAD_DETAILS', scenario.data.data)
            return scenario.data.data
        } catch (error) {
            return error
        }
    }


    @Action
    async ['CHECK_EXIST_PHONE_NUMBER'](payload) {
        this.context.commit('SET_QL_ENABLE', true) // put it in ql modal component
        const response = await ApiService.post('/skye/check-number-exist', payload)
        if(!response.data) this.context.commit('SET_QL_ENABLE', false)
        // this.context.commit('SET_EXIST_PHONE_NUMBER', response.data) // BND-1806
        return response.data
    }

    @Action
    async ['WITH_GLOBAL'](payload) {
        try {
            let url = getLink(payload.linkType)
            if(url.includes('PAGE_NUMBER')) url = url.replace('PAGE_NUMBER', payload.page)
            delete payload.linkType
            delete payload.page

            const response = await ApiService.post(url, payload)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_CUSTOMER_ACTIVITY_LOG'](payload) {
        try {
            const response = await ApiService.post('/skye/customer/list-activity-logs', payload)
            this.context.commit('SET_CUSTOMER_ACTIVITY_LOG', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_CUSTOMER_STATUS_LOG'](payload) {
        try {
            const response = await ApiService.post('/skye/customer/list-status-logs', payload)
            this.context.commit('SET_CUSTOMER_STATUS_LOG', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_ALL_TASK_BY_LEAD_AND_CUSTOMER_ID'](payload) {
        try {
            const response = await ApiService.post('/skye/task/car-lead-tasks', payload)
            this.context.commit('SET_ALL_TASK_BY_LEAD_AND_CUSTOMER_ID', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_ALL_NOTES_BY_LEAD_ID'](payload) {
        try {
            this.context.commit('SET_ALL_NOTES_BY_LEAD_ID', { notes: [] })
            const response = await ApiService.post('/skye/car-leads/notes', payload)
            this.context.commit('SET_ALL_NOTES_BY_LEAD_ID', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_LEAD_POLICIES'](payload) {
        try {
            const response = await ApiService.post('/skye/policies/list-car-customer-policies', payload)
            this.context.commit('SET_LEAD_POLICIES', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_DOCUMENT_LOG'] (payload) {
        try {
            const response = await ApiService.post('/skye/documents/list-document-logs', payload)
            this.context.commit('SET_DOCUMENT_LOG', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_POLICY_LOG'] (payload) {
        try {
            this.context.commit('SET_POLICY_LOG', {logs: []})
            const response = await ApiService.post('/skye/policies/list-policy-logs', payload)
            this.context.commit('SET_POLICY_LOG', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_EMAILS'] (payload) {
        try {
            const response = await ApiService.post('/skye/emails/list-emails', payload)
            this.context.commit('SET_EMAILS', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_MANUAL_QUOTES'] (payload) {
        try {
            const response = await ApiService.post('/skye/quote/list-manual-quotes', payload)
            this.context.commit('SET_MANUAL_QUOTES', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_SMS'] (payload) {
        try {
            const response = await ApiService.post('/skye/sms/list-sms', payload)
            this.context.commit('SET_SMS', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_RESPONSES'] (payload) {
        try {
            const scene:any = {
                car_lead_id: payload.lead_id,
                customer_id: payload.customer_id
            }
            const response = await ApiService.post('/skye/master/list-task-statuses', payload)
            const scenario = await ApiService.post('/skye/car-leads/check-scenario-eight', scene)
            this.context.commit('SET_RESPONSES', {responses: response.data.data})
            return scenario
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_LEAD_LOGS'] (payload) {
        try {
            const activity = await ApiService.post('/skye/car-leads/list-activity-logs', payload)
            const status = await ApiService.post('/skye/car-leads/list-status-logs', payload)
            this.context.commit('SET_LEAD_LOGS', {activity: activity.data.data.logs, status: status.data.data.logs})
            return { activity, status }
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_INSURANCE_PLAN'] (payload) {
        try {
            const response = await ApiService.post('/skye/policies/list-insurance-policies', payload)
            this.context.commit('SET_INSURANCE_PLAN', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_MARK_FLOW_DOCUMENTS'] (payload) {
        try {
            const response = await ApiService.post('/skye/documents/list-quote-request-document', payload)
            console.log({'GET_SET_MARK_FLOW_DOCUMENTS':response.data.data})
            this.context.commit('SET_MARK_FLOW_DOCUMENTS', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['MAKE_INVOICE'] (payload) {
        try {
            const response = await ApiService.post_form('/skye/invoice/add-invoice', payload)
            return response
        } catch (error:any) {
            return error.response
        }
    }
    
    // Mutations
    @Mutation
    ['SET_NEW_CUSTOMER'](payload) {
        this.customer = payload
    }

    @Mutation
    ['SET_CUSTOMER_DETAILS']({ customer }) {
        this.customerDetail = customer

        if(customer != undefined) {
            const edit = {
                customer_id: customer.id,
                name: customer.name,
                phone_country_code: '+971',
                phone_number: '',
                confirm_phone_country_code: '+971',
                confirm_phone_number: '',
                email: '',
                confirm_email: '',
                current_email: customer.email,
                current_phone_number: customer.phone_number,
                other_contact_info: '',
                current_other_contact_info: customer.other_contact_info ? customer.other_contact_info : '-'
            }
            this.customer_edit = edit
        }
    }

    @Mutation
    ['SET_CUSTOMER_DETAILS_EDIT']() {
        if(Object.keys(this.customerDetail).length > 0) {
            this.customer_edit = {
                customer_id: this.customerDetail.id,
                name: this.customerDetail.name,
                phone_country_code: '+971',
                phone_number: '',
                confirm_phone_country_code: '+971',
                confirm_phone_number: '',
                email: '',
                confirm_email: '',
                current_email: this.customerDetail.email,
                current_phone_number: this.customerDetail.phone_number,
                other_contact_info: '',
                current_other_contact_info: this.customerDetail.other_contact_info
            }
        }
    }

    @Mutation
    ['SET_CUSTOMER_LEAD_DETAILS']({ leads }) {
        this.leads = leads
    }

    @Mutation
    ['SET_CUSTOMER_LEAD_DETAILS_2']({ leads_2 }) {
        this.customer_detail_lead = leads_2.data
    }
    
    @Mutation
    ['SET_CUSTOMER_OPEN_LEAD_DETAILS']({ lead }) {
        // console.log(lead);
        if(lead){
            const result = {
                lead_status: lead.lead_status_text,
                is_end_state_lead: lead.is_end_state_lead,
                lead_status_id: lead.lead_status_id,
                highlight: lead.latest_task ? lead.latest_task.is_highlight_task : false,
                lead_id: lead.id,
                is_show_closed_lead: lead.is_show_closed_lead,
                is_show_closed_lead_from_llr_to_closed: lead.is_show_closed_lead_from_llr_to_closed,
                is_show_make_invoice: lead.is_show_make_invoice,
                agent: lead.agent,
                name: `${lead.car_year} | ${lead.make} | ${lead.model} | ${lead.model_details}`,
                customer_id: lead.customer_id,
                policy_expiry: lead.latest_policy_purchase && lead.latest_policy_purchase?.policy_active_status == true ? lead.latest_policy_purchase?.policy_end_date_format : '-',
                task_due: !lead.is_end_state_lead && lead?.latest_task?.status == 1 ? lead.latest_task?.due_date_format : 'No Task',
                lead: leadData(lead),
                customer_detail: customerDetailData(lead),
                driver_details: driverDetailsData(lead),
                policy_details: policyDetailsData(lead),
                with_policy: lead.latest_policy_purchase ? true : false,
                vehicle_type: lead.vehicle_type,
                hot_lead: lead.hot_lead === 1 ? true : false,
                hot_renewal_lead: lead.hot_renewal_lead === 1 ? true : false,
                disable_task_button: lead.lead_has_pending_invoice ? true : false
            }
            this.openLead = result
        }
    }

    @Mutation
    ['SET_LOADED_COMPONENT'](payload) {
        this.componentLoad = payload
    }

    @Mutation
    ['SET_EXIST_PHONE_NUMBER'](payload) {
        if(typeof payload === 'object') {
            this.existCustomer = payload
            this.isQLDisable = true
        } else {
            this.existCustomer = null
            this.isQLDisable = false
        }
    }

    @Mutation
    ['SET_QL_ENABLE'](payload) {
        this.isQLDisable = payload
    }

    @Mutation
    ['SET_RESET_CUSTOMER'](vehicle_type = 1) {
        this.customer = {
            name: '',
            email: '',
            phone_country_code: '+971',
            phone_number: '',
            car_year: 0,
            manufacturer_id: 0,
            model_id: 0,
            trim_level_id: 0,
            is_new: 2,
            car_value: 0,
            first_registration_date: '',
            registration_emirate: 2,
            dob: '',
            nationality: null,
            first_driving_license_country: null,
            driving_experience: 4,
            uae_driving_experience: 4,
            policy_start_date: moment().format('YYYY-MM-DD'),
            claimed_insurance: 2,
            no_claim_certificate: 1,
            claims: null,
            gcc_specification: 1,
            personal_use: 1,
            current_policy_active: 1,
            fully_comprehensive: 1,
            third_party_liability: 2,
            existing_policy_expired: 2,
            driver_name: null,
            agent: AgentDefaultRole ? user_id : null,
            gender: null,
            is_car: 1,
            lead_source: 'skye',
            is_vintage: 0,
            vehicle_type
        }
    }

    @Mutation
    ['SET_CUSTOMER_ACTIVITY_LOG']({ logs, logs_viewed }) {
        this.customerActivityLog = logs
        this.customerViewActivityLog = logs_viewed
    }
    
    @Mutation
    ['SET_CUSTOMER_STATUS_LOG']({ logs }) {
        this.customerStatusLog = logs
    }
    
    @Mutation
    ['SET_ALL_TASK_BY_LEAD_AND_CUSTOMER_ID']({ tasks }) {
        this.viewTasks = tasks
    }
    
    @Mutation
    ['SET_ALL_NOTES_BY_LEAD_ID']({ notes }) {
        this.notes = notes
    }

    @Mutation
    ['SET_LEAD_POLICIES']({policies}) {
        this.policies = policies
    }

    @Mutation
    ['SET_DOCUMENT_LOG']({logs}) {
        this.documentLogs = logs
    }

    @Mutation
    ['SET_POLICY_LOG']({logs}) {
        this.policyLogs = logs
    }

    @Mutation
    ['SET_EMAILS']({emails}) {
        this.emails = emails
    }

    @Mutation
    ['SET_MANUAL_QUOTES']({quotations}) {
        this.manualQuotes = quotations
    }

    @Mutation
    ['SET_SMS']({sms}) {
        this.sms = sms
    }

    @Mutation
    ['SET_RESPONSES']({ responses }) {
        this.responses = responses
    }

    @Mutation
    ['SET_LEAD_LOGS']({activity, status}) {
        this.leadActivityLog = activity
        this.leadStatusLog = status
    }

    @Mutation
    ['SET_INSURANCE_PLAN']({policies}) {
        this.insurancePlan = policies
    }

    @Mutation
    ['SET_AMEND_INVOICE'](data) {
        if(data) this.amend_invoice = data
    }

    @Mutation
    ['SET_MARK_FLOW_DOCUMENTS'](data) {
        this.markFlowViewQuotesList = data.documents
    }

    // getters
    get getCustomerDetailsTop(): CustomerTop {
        if(this.customerDetail && Object.keys(this.customerDetail).length > 0) {
            const { name, email, id, phone_number, hot_lead, hot_renewal_lead, agent_details, customer_status_id, customer_status_text, lead_open_task_count, other_contact_info } = this.customerDetail
            return {
                customer_id: id,
                name,
                email: email ? email : 'Update Email',
                complete_number: phone_number,
                customer_status_id,
                customer_status_text,
                agent_name: agent_details ? `SA: ${agent_details.name}` : 'Not Assigned',
                open_task_count: lead_open_task_count,
                hot_lead: hot_lead === 1 ? true : false,
                hot_renewal_lead: hot_renewal_lead === 1 ? true : false,
                other_contact_info: other_contact_info,
                name_email_phone: `${name} | ${ email ? email + ' |': '' } ${phone_number}`
            }
        }

        return {
            customer_id: 0,
            name: '',
            email: '',
            complete_number: '',
            customer_status_id: 0,
            customer_status_text: '',
            agent_name: '',
            open_task_count: 0,
            hot_lead: false,
            hot_renewal_lead: false,
            other_contact_info: '',
            name_email_phone: ''
        }
    }

    get getLeadDatasV2() {
        if(this.customer_detail_lead) {
            return this.customer_detail_lead
        }
        return []
    }

    get getLeadDatas() {
        if(this.leads != undefined) {
            return this.leads.map((x: any) => {
                return {
                    lead_status: x.lead_status_text,
                    is_end_state_lead: x.is_end_state_lead,
                    lead_status_id: x.lead_status_id,
                    highlight: x.latest_task ? x.latest_task.is_highlight_task : false,
                    lead_id: x.id,
                    is_show_closed_lead: x.is_show_closed_lead,
                    is_show_make_invoice: x.is_show_make_invoice,
                    agent: x.agent,
                    name: `${x.car_year} | ${x.make} | ${x.model} | ${x.model_details}`,
                    customer_id: x.customer_id,
                    policy_expiry: x.latest_policy_purchase && x.latest_policy_purchase?.policy_active_status == true ? x.latest_policy_purchase?.policy_end_date_format : '-',
                    task_due: !x.is_end_state_lead && x?.latest_task?.status == 1 ? x.latest_task?.due_date_format : 'No Task',
                    lead: leadData(x),
                    customer_detail: customerDetailData(x),
                    driver_details: driverDetailsData(x),
                    policy_details: policyDetailsData(x),
                    with_policy: x.latest_policy_purchase ? true : false,
                    vehicle_type: x.vehicle_type,
                    hot_lead: x.hot_lead === 1 ? true : false,
                    hot_renewal_lead: x.hot_renewal_lead === 1 ? true : false,
                }
            })
        }
        
        return []
    }

    get getOpenLeadDatas() {
        console.log('checksd sdkjsd')
        return this.openLead
        if(this.openLead && Object.keys(this.openLead).length > 0) {
            const x:any = this.openLead
            console.log('start',moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));

            const result = {
                lead_status: x.lead_status_text,
                is_end_state_lead: x.is_end_state_lead,
                lead_status_id: x.lead_status_id,
                highlight: x.latest_task ? x.latest_task.is_highlight_task : false,
                lead_id: x.id,
                is_show_closed_lead: x.is_show_closed_lead,
                is_show_closed_lead_from_llr_to_closed: x.is_show_closed_lead_from_llr_to_closed,
                is_show_make_invoice: x.is_show_make_invoice,
                agent: x.agent,
                name: `${x.car_year} | ${x.make} | ${x.model} | ${x.model_details}`,
                customer_id: x.customer_id,
                policy_expiry: x.latest_policy_purchase && x.latest_policy_purchase?.policy_active_status == true ? x.latest_policy_purchase?.policy_end_date_format : '-',
                task_due: !x.is_end_state_lead && x?.latest_task?.status == 1 ? x.latest_task?.due_date_format : 'No Task',
                lead: leadData(x),
                customer_detail: customerDetailData(x),
                driver_details: driverDetailsData(x),
                policy_details: policyDetailsData(x),
                with_policy: x.latest_policy_purchase ? true : false,
                vehicle_type: x.vehicle_type,
                hot_lead: x.hot_lead === 1 ? true : false,
                hot_renewal_lead: x.hot_renewal_lead === 1 ? true : false,
            }
            console.log('result',result)
            console.log('end',moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));

            return result;
        }

        
        return {}
    }

    get getCustomerLogs() {
        return {
            activity_log: activity_log(this.customerActivityLog),
            status_log: status_log(this.customerStatusLog),
            view_activity_log: view_activity_log(this.customerViewActivityLog)
        }
    }
    
    get getLeadActivityLogs() {
        return {
            activity_log: activity_log(this.leadActivityLog),
            status_log: status_log(this.leadStatusLog)
        }
    }

    get getLeadCounts() {
        // return this.leads.length
        return this.customer_detail_lead.length
    }

    get getTabDisabled() {
        // if(this.leads.length < 2) {
        //     return this.leads.find((x: any) => [2, 9, 10].includes(x.lead_status_id)) ? true : false
        // }
        if(this.customer_detail_lead.length < 2) {
            return this.customer_detail_lead.find((x: any) => [2, 9, 10].includes(x.lead_status_id)) ? true : false
        }
        return false
    }

    get getViewDetails() {
        // if(this.leads !== undefined) {
        //     return this.leads.map((x: any) => {
        //         return {
        //             agent: x.agent,
        //             car_value: parseFloat(x.lead_driver_detail.car_value),
        //             car_year: parseInt(x.car_year),
        //             claimed_insurance: x.lead_driver_detail.claimed_insurance,
        //             claims: x.lead_driver_detail.claims,
        //             current_policy_active: x.lead_driver_detail.current_policy_active,
        //             customer_id: x.customer_id,
        //             dob: x.lead_driver_detail.dob,
        //             driver_details_id: x.lead_driver_detail.id,
        //             driver_name: x.lead_driver_detail.driver_name,
        //             driving_experience: x.lead_driver_detail.driving_experience,
        //             dummy_email: this.customerDetail.email,
        //             email: this.customerDetail.email,
        //             existing_policy_expired: x.lead_driver_detail.existing_policy_expired,
        //             first_driving_license_country: x.lead_driver_detail.first_driving_license_country,
        //             first_registration_date: x.first_registration_date,
        //             fully_comprehensive: x.lead_driver_detail.fully_comprehensive,
        //             gcc_specification: x.gcc_specification,
        //             gender: x.lead_driver_detail.gender,
        //             is_car: 1,
        //             is_new: x.is_new,
        //             is_vintage: x.is_vintage,
        //             lead_id: x.id,
        //             lead_source: x.lead_source,
        //             manufacturer_id: x.manufacturer_id,
        //             model_id: x.model_id,
        //             name: this.customerDetail.name,
        //             nationality: x.lead_driver_detail.nationality,
        //             no_claim_certificate: parseInt(x.lead_driver_detail.no_claim_certificate),
        //             personal_use: x.lead_driver_detail.personal_use,
        //             phone_country_code: this.customerDetail.phone_country_code,
        //             phone_number: this.customerDetail.phone_number_without_code,
        //             policy_start_date: x.lead_driver_detail.policy_start_date,
        //             policy_type: 0,
        //             registration_emirate: x.registration_emirate,
        //             third_party_liability: x.lead_driver_detail.third_party_liability,
        //             trim_level_id: x.trim_level_id,
        //             uae_driving_experience: x.lead_driver_detail.uae_driving_experience,
        //             vehicle_type: x.vehicle_type
        //         }
        //     })
        // }

        // if(this.leads !== undefined) {
            return (x) => {
                // this.leads.map((x: any) => {
                    return {
                        agent: x.agent,
                        car_value: parseFloat(x.lead_driver_detail.car_value),
                        car_year: parseInt(x.car_year),
                        claimed_insurance: x.lead_driver_detail.claimed_insurance,
                        claims: x.lead_driver_detail.claims,
                        current_policy_active: x.lead_driver_detail.current_policy_active,
                        customer_id: x.customer_id,
                        dob: x.lead_driver_detail.dob,
                        driver_details_id: x.lead_driver_detail.id,
                        driver_name: x.lead_driver_detail.driver_name,
                        driving_experience: x.lead_driver_detail.driving_experience,
                        dummy_email: this.customerDetail.email,
                        email: this.customerDetail.email,
                        existing_policy_expired: x.lead_driver_detail.existing_policy_expired,
                        first_driving_license_country: x.lead_driver_detail.first_driving_license_country,
                        first_registration_date: x.first_registration_date,
                        fully_comprehensive: x.lead_driver_detail.fully_comprehensive,
                        gcc_specification: x.gcc_specification,
                        gender: x.lead_driver_detail.gender,
                        is_car: 1,
                        is_new: x.is_new,
                        is_vintage: x.is_vintage,
                        lead_id: x.id,
                        lead_source: x.lead_source,
                        manufacturer_id: x.manufacturer_id,
                        model_id: x.model_id,
                        name: this.customerDetail.name,
                        nationality: x.lead_driver_detail.nationality,
                        no_claim_certificate: parseInt(x.lead_driver_detail.no_claim_certificate),
                        personal_use: x.lead_driver_detail.personal_use,
                        phone_country_code: this.customerDetail.phone_country_code,
                        phone_number: this.customerDetail.phone_number_without_code,
                        policy_start_date: x.lead_driver_detail.policy_start_date,
                        policy_type: 0,
                        registration_emirate: x.registration_emirate,
                        third_party_liability: x.lead_driver_detail.third_party_liability,
                        trim_level_id: x.trim_level_id,
                        uae_driving_experience: x.lead_driver_detail.uae_driving_experience,
                        vehicle_type: x.vehicle_type
                    }
                // })
            }
        // }
        return []
        // return []
    }

    get getAllLeads() {
        // if(this.leads !== undefined) {
        //     return this.leads.map((x: any) => {
        //         return {
        //             lead_id: x.id,
        //             customer_id: x.customer_id,
        //             agent: x.agent,
        //             lead_status_id: x.lead_status_id,
        //             is_show_closed_lead: x.is_show_closed_lead,
        //             name: `${x.car_year} | ${x.make} | ${x.model} | ${x.model_details}`,
        //             tab_disabled: [2, 9, 10].includes(x.lead_status_id),
        //             with_policy: x.latest_policy_purchase ? true : false,
        //             vehicle_type: x.vehicle_type
        //         }
        //     })
        // }

        if(this.customer_detail_lead !== undefined) {
            return this.customer_detail_lead.map((x: any) => {
                return {
                    lead_id: x.lead_id,
                    customer_id: x.customer_id,
                    agent: x.agent,
                    lead_status_id: x.lead_status_id,
                    is_show_closed_lead: x.is_show_closed_lead,
                    // name: `${x.car_year} | ${x.make} | ${x.model} | ${x.model_details}`,
                    name: x.name,
                    tab_disabled: [2, 9, 10].includes(x.lead_status_id),
                    // with_policy: x.latest_policy_purchase ? true : false,
                    with_policy: x.with_policy,
                    vehicle_type: x.vehicle_type,
                    disable_task_button: x.has_pending_invoice
                }
            })
        }
        return []
    }

    get getLeadDropDown() {
        // if(this.leads !== undefined) {
        //     return this.leads.filter((x:any) => ![2, 10].includes(x.lead_status_id)).map((x:any) => {
        //             return {
        //                 value: x.id,
        //                 label: `${x.car_name_with_driver} | ${x.id}`,
        //                 is_show_make_invoice: x.is_show_make_invoice,
        //                 lead_status_id: x.lead_status_id,
        //                 policy_purchase_status: x?.latest_policy_purchase?.status,
        //                 latest_policy_purchase: x?.latest_policy_purchase
        //             }
        //     })
        // }
        if(this.customer_detail_lead !== undefined) {
            return this.customer_detail_lead.filter((x:any) => ![2, 10].includes(x.lead_status_id)).map((x:any) => {
                    return {
                        customer_id: x.customer_id,
                        value: x.lead_id,
                        label: `${x.name} | ${x.lead_id}`,
                        is_show_make_invoice: x.is_show_make_invoice,
                        lead_status_id: x.lead_status_id,
                        policy_purchase_status: x.policy_purchase_status,
                        show_upload_docs: x.show_upload_docs
                    }
            })
        }
        return []
    }

    get getAllTasks() {
        if(this.viewTasks !== undefined) {
            return this.viewTasks.map((x: any) => {
                return {
                    current_data: `${x.agent_details ? x.agent_details.name + ' | ' : ''}${x.disposition_text} | <span class="text-blue mx-2">${x.status_text}</span>`,
                    lost_lead_reason: x.lost_lead_reason_text,
                    show_lost_lead_reason: [8, 19].includes(x.disposition_id) ? true : false,
                    show_close_task: x.status === 1 && ![7, 11, 15].includes(role_id)? true : false,
                    lead_status: x.lead_status_text,
                    task_note: x.task_notes,
                    class_text: x.class_text,
                    due_at: x.due_date_format,
                    by: `${x.created_by ? x.created_by.name + ' | ' : ''}${x.created_at}`,
                    closed_at: x.closed_at,
                    customer_id: x.customer_id,
                    agent: x.agent,
                    lead_id: x.lead_id,
                    lead_status_id: x.lead_status_id
                }
            })
        }
        return []
    }

    get getPolicies() {
        if(this.policies !== undefined) {
            return this.policies.map((x:any) => {
                    const show_view_policy = x.policy_document ? true : false
                    const show_view_all_documents = x.car_lead_documents && x.car_lead_documents.length > 0 ? true : false
                    const show_view_cancellationDocuments = x.status === 7 ? true : false

                return {
                    policy_id: x.id,
                    show_action: [1,2,3,4,7].includes(x.status) && (show_view_policy || show_view_all_documents || show_view_cancellationDocuments),
                    is_active: x.is_active === 1 ? true : false,
                    is_amend: x.is_amended === 1 ? true : false,
                    quote_ref_no: x.car_lead_quote.quote_ref_no,
                    status_text: x.status_text,
                    status: x.status,
                    email_mobile: `${x.customer.email}<br />${x.customer.phone_number}`,
                    customer_name: x.customer.name,
                    price_vat: `${common.formatCurrency(x.policy_price_amount)} <br />${common.formatCurrency(x.car_lead_invoice.vat)}`,
                    price: common.formatCurrency(x.policy_price_amount),
                    price_vat_inline: `${common.formatCurrency(x.policy_price_amount)} (${common.formatCurrency(x.car_lead_invoice.vat)})`,
                    price_inline: common.formatCurrency(x.policy_price_amount),
                    amend_invoices: x?.car_lead_invoice?.amend_invoices ? amend_invoices(x.car_lead_invoice.amend_invoices) : [],
                    amend_invoices_without_vat: x?.car_lead_invoice?.amend_invoices ? amend_invoices_without_vat(x.car_lead_invoice.amend_invoices) : [],
                    discrepancy_amount: common.formatCurrency(x?.car_lead_invoice?.discrepancy_amount ? x.car_lead_invoice.discrepancy_amount : 0),
                    transaction_type_text: x.transaction_type_text,
                    policy_type: x?.car_insurance_policy?.policy_type == 5 ? 'TPL' : 'COMP',
                    policy_name: x?.car_insurance_policy?.policy_name ? x.car_insurance_policy.policy_name : '<span class="text-danger">-</span>',
                    created_on: `${x.created_at ? x.created_at : '-'}<br />${x.issue_date ? x.issue_date : '-'}`,
                    policy_start_date: x.policy_start_date_format ? x.policy_start_date_format : '-',
                    policy_end_date: x.policy_end_date_format ? x.policy_end_date_format : '-',
                    upsa: `${x.policy_underwriter ? x.policy_underwriter.name : '<span class="text-danger"> Not assigned</span>'}<br />${ x.policy_sales_agent ? x.policy_sales_agent.name : '-' }`,
                    ibco: `${x?.car_lead_invoice?.created_by ? x.car_lead_invoice.created_by.name : '-'}<br />${ x.car_lead_invoice ? x.car_lead_invoice.created_at : '-' }`,
                    car_value: common.formatCurrency(x?.car_lead_quote?.car_lead_driver_details ? x?.car_lead_quote?.car_lead_driver_details?.car_value : 0),
                    insurance_payment_text: x.insurance_payment_text ? x.insurance_payment_text : '-',
                    show_view_policy,
                    show_view_all_documents,
                    show_view_cancellationDocuments,
                    policy_document: x.policy_document ? x.policy_document.file_path : '',
                    policy_documents: allDocuments(x.car_lead_documents, x.car_lead_quote.quote_ref_no),
                    cancellation_documents: allDocuments(x.car_lead_cancellation_documents, x.car_lead_quote.quote_ref_no, 'cancellation', false)
                }
            })
        }

        return []
    }

    get getPolicyLogItems() {
        if(this.policies !== undefined) {
            return this.policies.map((x:any) => {
                return {
                    label: `${x?.car_insurance_policy?.policy_name ? x.car_insurance_policy.policy_name : ''} | ${x.car_lead_quote.quote_ref_no} | ${moment(x.created_at, 'DD/MM/YYYY HH:mm').format('YYYY')}`,
                    value: x.id
                }
            })
        }
        return []
    }

    get getDocumentLogLists() {
        if(this.documentLogs !== undefined) {
            return this.documentLogs.map((x:any) => {
                return {
                    action: x.action,
                    by: x.action_by.name,
                    at: x.created_at
                }
            })
        }

        return []
    }

    get getPolicyLogLists() {
        if(this.policyLogs !== undefined) {
            return this.policyLogs.map((x:any) => {
                let policy_status = ''
                let action = ''
                let current_data = ''
                let previous_data = ''
                if(['refund-approved','refund-decline','refund-completed','policy-updated','remove-scan-data'].includes(x.activity_slug)) {
                    policy_status = x?.activity_description?.new?.policy_status
                    current_data = setCurrentData(x?.activity_description?.new)
                    previous_data = setCurrentData(x?.activity_description?.old)
                }
                if(['policy-discrepancy'].includes(x.activity_slug)) {
                    action = x?.activity_description?.new?.action
                    current_data = setCurrentData(x?.activity_description?.new, true)
                    previous_data = setCurrentData(x?.activity_description?.old, true)
                }
                if(['policy-transferred', 'sales-transferred'].includes(x.activity_slug)) {
                    current_data = x?.activity_description?.new?.name
                    previous_data = x?.activity_description?.old?.name
                }
                if(['policy-discrepancy'].includes(x.activity_slug)) {
                    current_data = x?.activity_description?.new?.created_at
                }
                if(['updated-completed-policy-info','updated-cancelled-policy-info'].includes(x.activity_slug)) {
                    policy_status = x?.activity_description?.policy_status
                    current_data = x?.activity_description?.new
                    previous_data = x?.activity_description?.old
                }

                if(['remove-scan-data'].includes(x.activity_slug)) 
                {
                    const old = x?.activity_description?.old
                    const newd = x?.activity_description?.new
                    policy_status = x?.activity_description?.new?.policy_status
                    previous_data = `${old.customer_name} | ${old.emirates_id} | ${old.chassis_number}`
                    current_data = `${newd.customer_name ?? '-'} | ${newd.emirates_id ?? '-'} | ${newd.chassis_number ?? '-'}`
                    
                }

                return {
                    title: x.activity_desc,
                    policy_status,
                    action,
                    current_data,
                    previous_data,
                    by: x.action_by ? `${x?.action_by?.name} at ${moment(x.created_at, 'DD/MM/YYYY HH:mm').zone('+0400').format('DD/MM/YYYY HH:mm')}` : ''
                }
            })
        }

        return []
    }

    get getEmails() {
        if(this.emails !== undefined) {
            return this.emails.map((x:any) => {
                return {
                    subject: x.subject,
                    sent_by: x.sent_by_skye_user.name,
                    created_at: x.created_at,
                    delivered: x.delivered == 1 ? true : false,
                    clicked: x?.clicked == 1 ? true : false,
                    email_body: x.email_body
                }
            })
        }

        return []
    }

    get getViewQuotes() {
        if(this.manualQuotes !== undefined) {
            return this.manualQuotes.map((x:any) => {
                return {
                    id: x.id,
                    car_details: `${x.car_year} | ${x.make} | ${x.model} | ${x.model_details}`,
                    policy_type: x.is_mark_flow == 1 ? x.mark_flow_policy_type_text : x.policy_type_text,
                    generate_on: x.created_at,
                    generate_by: x.created_by?.name,
                    companies: x.companies,
                    payment_link: x.payment_requested == 2 ? x.payment_link : x.payment_requested_text,
                    file_path: x.file_path,
                    encrypted_file_path: x.encrypted_file_path,
                    showDownload: true,
                    showSendQuote: true,
                    showWhatsapp: true,
                    is_mark_flow: x.is_mark_flow == 1 ? true : false,
                    whatsapp_number: this.customerDetail.whatsapp_number,
                    quote_request_doc: x.quote_request_doc,
                    payment_requested: x.payment_requested,
                    short_url_path: x.short_url_path,
                }
            })
        }

        return []
    }

    get getSMS() {
        if(this.sms !== undefined) {
            return this.sms.map((x:any) => {
                return {
                    sms_content: x.sms,
                    created_at: x.created_at,
                    status: x.status == 1 ? 'Delivered' : 'Not delivered'
                }
            })
        }
        return []
    }

    get getResponses() {
        const store = useStore()
        const leads = store.getters.getLeadDatasV2
        // BND-2033 : Filter leads other than status NEW/QL and no policy
        const otherLeadStatus = leads.filter((x) => {
            // if(x.with_policy)
            //     return x;
            
            if(![1,2].includes(x.lead_status_id))
                return x;
                
        });
        return (lead_status_id, with_policy) => {

            // console.log('leads',with_policy)

            const resonseKey = getResponseObject(lead_status_id, with_policy);
            let responses = this.responses[resonseKey]

            if(responses && leads.length <= 1 && resonseKey == 'statuses'){
                // BND-2033 : filter out redudant lead if only single lead present
                responses = responses.filter(x => x.value != 17)
            }
            
            if(responses && otherLeadStatus.length > 0){
                // BND-2033 : filter out false lead if other leads are present
                responses = responses.filter(x => x.value != 11)
            }


            return responses
        }
    }

    get getVehicleListByType() {
        // if(this.leads != undefined) {
        //     return (vehicle_type = 1) => {
        //         const vehicle = this.leads.filter((x:any) => x.vehicle_type === vehicle_type)
        //         return vehicle.map((x: any) => {
        //             return {
        //                 label: `${x.car_year} | ${x.make} | ${x.model} | ${x.model_details}`,
        //                 value: x.id
        //             }
        //         })
        //     }
        // }
        // console.log('customer_detail_lead', this.customer_detail_lead)

        if(this.customer_detail_lead != undefined) {
            return (vehicle_type = 1) => {
                const vehicle = this.customer_detail_lead.filter((x:any) => x.vehicle_type === vehicle_type)
                return vehicle.map((x: any) => {
                    return {
                        // label: `${x.car_year} | ${x.make} | ${x.model} | ${x.model_details}`,
                        label: `${x.name}`,
                        value: x.lead_id
                    }
                })
            }
        }

        return []
    }

    get ProduceDocuments() {
        return (documents, quote_ref_no, module = 'policy', is_completed = true, types = [], params = null) => {
            return allDocuments(documents, quote_ref_no, module, is_completed, types, params) 
        }
    }

    get ProduceAmendInvoice() {
        return (data, amend_type) => {
            if(amend_type === 3) return setANT(data, amend_type)
            else return setNotNT(data, amend_type)
        }
    }

    get getMarkFlowQuoteDocs() {
        // return {'ss':'ss'};
        console.log({ 'getmarkfa':this.markFlowViewQuotesList })
        if(this.markFlowViewQuotesList !== undefined) {
            return this.markFlowViewQuotesList.map((x:any) => {
                return {
                    id: x.id,
                    manual_quotation_id: x.manual_quotation_id,
                    quote_request_id: x.quote_request_id,
                    customer_id: x.customer_id,
                    file_path: x.file_path,
                    file_extension: x.file_extension,
                    payment_requested: x.payment_requested,
                    policy_type: x.policy_type,
                    insurance_policies: x.insurance_policies,
                    original_filename: x.original_filename,
                    created_at: x.created_at,
                    created_by_skye_user: x.created_by_skye_user ? x.created_by_skye_user.email : '',
                    created_by_customer: x.created_by_customer ? x.created_by_customer.email : '',
                    notes: x.notes,
                    car_insurance_id: x.car_insurance_id,
                }
            })
        }
        return []
    }

}

function getLink(value: string) {
    switch(value) {
        case 'update-name': return '/skye/customer/update-customer-name'
        case 'update-email': return '/skye/customer/update-customer-email'
        case 'update-phone': return '/skye/customer/update-customer-phone'
        case 'update-other-contact-info': return '/skye/customer/update-other-contact-info'
        case 'transfer-leads': return '/skye/car-transfer-lead'
        case 'transfer-sale': return '/skye/policies/transfer-sale'
        case 'transfer-underwriter': return '/skye/policies/transfer-underwriter'
        case 'customer-add-detail': return '/skye/car-detailed-lead'
        case 'customer-add-new-bike': 
        case 'customer-add-bike-detail': return '/skye/bike-detailed-lead'
        case 'add-driver-details': 
        case 'add-lead-driver-details': 
        case 'new-car-quote': return '/skye/car-leads/add-driver-details'
        case 'edit-quote': return '/skye/car-leads/update-driver-details'
        case 'edit-bike-quote': return '/skye/bike-leads/update-driver-details'
        case 'generate-driver-details-manaual-quote':
        case 'generate-manaual-quote': return '/skye/quote/add-manual-quote'
        case 'add-notes': return '/skye/car-leads/add-note'
        case 'document-logs': return '/skye/documents/add-document-log'
        case 'compose-email': return '/skye/emails/send-email'
        case 'lead-policy-status': return '/skye/policies/get-policy-lead'
        case 'completed-documents': return '/skye/policies/list-lead-policy-documents'
        case 'active-underwriters': return '/skye/get-active-underwriters'
        case 'temp-completed-documents': return '/skye/policies/list-temp-and-policy-document'
        case 'temp-documents': return '/skye/documents/list-temp-document'
        case 'cancel-documents': return '/skye/policies/list-cancel-policy-doc'
        case 'amend-documents': return '/skye/documents/list/amends'
        case 'document-download': return '/skye/master/generate-zip'
        case 'document-types': return '/skye/master/document-types'
        case 'task-lead-reasons': return '/skye/master/task-lead-reasons'
        case 'cancellation-reasons': return '/skye/master/cancellation-reasons'
        case 'refund-reasons': return '/skye/master/refund-reasons'
        case 'list-user-types': return '/skye/master/list-user-types'
        case 'payment-types': return '/skye/master/payment-types'
        case 'document-type-update': return '/skye/documents/update-temp-document'
        case 'delete-document': return '/skye/documents/delete-temp-document'
        case 'delete-policy-document': return 'skye/policies/delete-policy-document'
        case 'send-quote-request': return '/skye/car-leads/quote-request'
        case 'previous-year-documents': return '/skye/policies/list-previous-year-lead-policy-documents'
        case 'insurance-companies': return '/skye/quote/list-insurance-policies'
        case 'send-manual-quote': return '/skye/quote/send-manual-quote'
        case 'add-lead-task': return '/skye/task/add-lead-task'
        case 'update-close-lead': return '/skye/car-leads/update-closed-lead'
        case 'ql-merge-lead': return '/skye/merge-quick-car-lead'
        case 'new-merge-lead': return '/skye/car-leads/merge-new-car-lead'
        case 'send-quote': return '/skye/quote/send-quote'
        case 'get-cancelled-policies': return '/skye/policies/list-cancelled-policies'
        case 'send-email-it': return '/skye/emails/send-email-invoice-issue'
        case 'get-policy-amount-accepted': return '/skye/policies/get-policy-amount-accepted'
        case 'email-activity': return '/skye/policies/list-email-activiy'
        case 'policy-restrict-dates': return '/skye/settings/policy-start-date-days-restrict'
        case 'send-mail-customer': return '/skye/policies/send-email-customer'
        case 'send-mail-broker': return '/skye/policies/send-email-insurance-broker'
        case 'ocr-check': return '/skye/policies/ocr-reader'
        case 'save-as-draft': return '/skye/policies/save-draft'
        case 'upload-policy': return '/skye/policies/add-car-customer-policy'
        case 'initiate-cancellation': return '/skye/policies/cancel-policy'
        case 'request-cancellation': return '/skye/policies/edit-cancel-policy'
        case 'approve-cancellation': return '/skye/policies/edit-cancellation'
        case 'initiate-refund': return '/skye/policies/refund'
        case 'approve-refund': return '/skye/policies/edit-refund'
        case 'customer-list': return '/skye/customer-list?page=PAGE_NUMBER'
        case 'policy-cancellation-request':
        case 'policy-refund-closed':
        case 'policy-cancelled':
        case 'policy-cancellation-approval':
        case 'policy-assigned': return '/skye/policies/list-policies-v3?page=PAGE_NUMBER'
        case 'policy-completed': return '/skye/policies/list-policies-v3?page=PAGE_NUMBER'
        case 'policy-refund-approval': return '/skye/policies/list-refund-policies?page=PAGE_NUMBER'
        case 'declined-invoice':
        case 'invoice-list': return '/skye/invoice/list-invoice-2?page=PAGE_NUMBER'
        case 'policy-approval': return '/skye/invoice/list-approval-invoice'
        case 'customer-list-count': return '/skye/customer-list-count'
        case 'agent-list': return '/skye/agent-list'
        case 'agent-list-dom': return '/skye/agent-list-for-dom-new'
        case 'underwriter-list': return '/skye/underwriter-list'
        case 'customer-car-lead-count': return '/skye/customer-car-lead-count'
        case 'car-task-due-date-count': return '/skye/car-task-due-date-count'
        case 'car-filtered-task-due-date-count': return '/skye/car-filtered-task-due-date-count'
        case 'list-policies-count': return '/skye/policies/list-policies-count'
        case 'list-invoice-count': return '/skye/invoice/list-invoice-count'
        case 'invoice-for-approval-count': return '/skye/invoice/list-approval-invoice-count'
        case 'has-child-policy': return '/skye/policies/get-child-invoices'
        case 'has-child-invoice': return '/skye/invoice/get-child-invoices'
        case 'amend-invoice': return '/skye/invoice/amend-invoice'
        case 'initiate-ge': return '/skye/invoice/initiate-ge'
        case 'initiate-invoice-refund': return '/skye/refunds/initiate-amend-refunds'
        case 'update-data': return '/skye/policies/update-data'
        case 'validate-promo': return '/skye/promotions/validate/code'
        case 'check-promo-exists': return '/skye/promotions/validate/exists'
        case 'list-quote-request-document': return '/skye/documents/list-quote-request-document'
        case 'payment-link-request': return '/skye/car-leads/payment-link-request'
        case 'invoice-scheduler': return '/skye/master/trigger-invoice-status-cron'
        case 'invoice-data': return '/skye/invoice/get-online-payment-info'
        case 'add-quick-lead': return 'skye/car-quick-lead'
        case 'make-invoice-event': return '/skye/make-invoice-event'
        case 'update-invoice': return '/skye/invoice/update-invoice'
        case 'marketing-campaigns': return '/skye/marketing/campaigns/listing'
        case 'marketing-spend': return '/skye/marketing/campaigns/spend/filters'
        case 'marketing-spend-tracking': return '/skye/marketing/campaigns/spend/tracking'
        case 'marketing-spend-add-update': return '/skye/marketing/campaigns/spend/addUpdate'
        case 'marketing-report': return '/skye/reports/campaign-report'
        case 'marketing-report-agent': return '/skye/reports/campaign-report'
        case 'get-active-inactive-agents': return '/skye/get-active-inactive-agents'
        case 'get-documents': return '/skye/policies/get-all-documents'
        case 'remove-scan-data': return '/skye/policies/remove-scan-data'
        case 'lead-source-filter': return '/skye/reports/master/lead-source-filter'
        case 'lead-by-source-filter': return '/skye/reports/master/lead-by-source-filters'
        case 'user-list-for-dom': return '/skye/users-list-for-dom'
        case 'dashboard-overview': return '/skye/dashboard/overviews'
        case 'dashboard-sales-person-info': return '/skye/dashboard/analytics/get-sales-person-info'
        case 'dashboard-sales-statistics': return '/skye/dashboard/analytics/get-sales-statistics'
        case 'dashboard-sales-others': return '/skye/dashboard/analytics/get-sales-others'
        case 'dashboard-SA-SM-TL': return '/skye/dashboard/analytics/get-sales-agent-manager'
        case 'dashboard-underwriter': return '/skye/dashboard/policy-creator-stats'
        case 'dashboard-accounts': return '/skye/dashboard/accounts-dashboard'
        case 'dashboard-marketing': return '/skye/dashboard/graph/lead-sources'
        case 'master-policy-status': return '/skye/master/policy-statuses'
        case 'master-lead-status': return '/skye/master/list-car-lead-statuses'
        case 'master-underwriter-list': return '/skye/underwriter-list'
        case 'user-logs': return '/skye/dashboard/user-recent-log?page=PAGE_NUMBER'
        case 'new-leads': return '/skye/dashboard/leads-new?page=PAGE_NUMBER'
        case 'pending-leads': return '/skye/dashboard/leads-pending?page=PAGE_NUMBER'
        case 'lost-leads': return '/skye/dashboard/leads-lost?page=PAGE_NUMBER'
        case 'cod-transaction': return '/skye/dashboard/list-cod-transactions?page=PAGE_NUMBER'
        case 'online-transaction': return '/skye/dashboard/list-online-transactions?page=PAGE_NUMBER'
        case 'direct-transaction': return '/skye/dashboard/list-direct-transactions?page=PAGE_NUMBER'
        case 'report-policy': return '/skye/reports/policies-assigned-vs-completed?page=PAGE_NUMBER' // report-policies-assigned-vs-completed
        case 'report-policy-export': return '/skye/reports/exports/policies-assigned-vs-completed?page=PAGE_NUMBER' // report-policies-assigned-vs-completed export
        case 'report-agent-sales': return '/skye/reports/agent-sales?page=PAGE_NUMBER' // agent-sales
        case 'report-agent-sales-export': return '/skye/reports/exports/agent-sales?page=PAGE_NUMBER' // agent-sales export
        case 'admin-report': return '/skye/reports/dashboard-reports'
        case 'admin-report-export': return '/skye/reports/exports/dashboard-reports'
        case 'report-approved-invoice': return '/skye/reports/approved-invoice?page=PAGE_NUMBER'
        case 'report-approved-invoice-export': return '/skye/reports/exports/approved-invoice?page=PAGE_NUMBER'
        case 'report-account-master': return '/skye/reports/account-master?page=PAGE_NUMBER'
        case 'report-account-master-export': return '/skye/reports/exports/account-master?page=PAGE_NUMBER'
        case 'report-discrepancy': return '/skye/reports/discrepancy?page=PAGE_NUMBER'
        case 'report-discrepancy-export': return '/skye/reports/exports/discrepancy?page=PAGE_NUMBER'
        case 'dashboard-details-report': return '/skye/reports/dashboard-details?page=PAGE_NUMBER'
        case 'dashboard-details-report-export': return '/skye/reports/exports/dashboard-details?page=PAGE_NUMBER'
        case 'report-lead-by-source': return '/skye/reports/lead-by-source?page=PAGE_NUMBER'
        case 'report-lead-by-source-export': return '/skye/reports/exports/lead-by-source?page=PAGE_NUMBER'
        case 'report-skye-login-info': return '/skye/reports/login-info?page=PAGE_NUMBER'
        case 'report-skye-login-info-export': return '/skye/reports/exports/login-info?page=PAGE_NUMBER'
        case 'report-closed-leads': return '/skye/reports/closed-leads?page=PAGE_NUMBER'
        case 'report-closed-leads-export': return '/skye/reports/exports/closed-leads?page=PAGE_NUMBER'
        case 'report-deleted-leads': return '/skye/reports/deleted-leads?page=PAGE_NUMBER'
        case 'report-deleted-leads-export': return '/skye/reports/exports/deleted-leads?page=PAGE_NUMBER'
        case 'generate-production-report': return '/skye/reports/generate-al-manarah-report'
        case 'al-manarah-report': return '/skye/reports/al-manarah-report?page=PAGE_NUMBER'
        case 'upload-doc-pending': return '/skye/car-leads/upload-doc-pending'
        case 'renewal-status': return '/skye/reports/renewal-status?page=PAGE_NUMBER'
        case 'lost-lead-report': return '/skye/reports/lost-lead?page=PAGE_NUMBER'
        case 'agent-escalations': return '/skye/reports/agent-escalations?page=PAGE_NUMBER'
        case 'underwriter-escalations': return '/skye/reports/underwriter-escalations?page=PAGE_NUMBER'
        case 'sales-tracking-report': return '/skye/reports/sales-tracking-report'
        case 'get-upload-receipt': return '/skye/invoice/get-receipt-details'
        case 'reset-password': return '/skye/user-reset-password'
        case 'request-reset-password': return '/skye/user-request-reset-password'
        case 'agent-lead-activity-report': return '/skye/reports/agent-lead-activity-report?page=PAGE_NUMBER'
        case 'start-break': return '/skye/start-break'
        case 'stop-break': return '/skye/stop-break'
        case 'get-active-break': return '/skye/get-active-break'
        default: return ''
    }
}

function activity_log(logs) {
    if(logs != undefined) {
        return logs.map(x => {
            let current_data = x.activity_description.new
            let showCurrentData = true
            let showPrevData = true
            if(current_data && ['transfer-customer','agent-assigned','agent-unassigned','customer-handover', 'transfer-lead', 'lead-handover', 'task-handover', 'updated-policy-sales-agent'].includes(x.activity_slug)) {
                current_data = current_data.name
            } 
            let previous_data = x.activity_description.old
            if(previous_data && ['transfer-customer','agent-assigned','agent-unassigned','customer-handover', 'transfer-lead', 'lead-handover', 'task-handover', 'updated-policy-sales-agent'].includes(x.activity_slug)) {
                previous_data = previous_data.name
                if(['updated-policy-sales-agent'].includes(x.activity_slug)) showPrevData = false
            }

            if(['added-notes', 'quick-lead-merged', 'false-lead', 'invoice-made', 'invoice-decline', 'invoice-decline-lost-lead'].includes(x.activity_slug)) {
                showPrevData = false
            }

            if(['is-hot-lead','not-hot-lead','is-hot-renewal-lead'].includes(x.activity_slug)) {
                showCurrentData = false
                showPrevData = false
            }
            


            return {
                title: x.activity_desc,
                current_data: current_data ? current_data : '',
                previous_data: previous_data ? previous_data : '',
                by: `${x.action_by ? x.action_by.name : ''} ${common.setDate(x.created_at)}`,
                showPrevData,
                showCurrentData,
                showBy: true
            }
        })
    }
}

function status_log(logs) {
    if(logs != undefined) {
        return logs.map(x => {
            return {
                current_status: x.activity_description ? x.activity_description.new : x.new_status_text ? x.new_status_text : '',
                previous_status: x.activity_description ? x.activity_description.old : x.old_status_text !== 0 ? x.old_status_text : '',
                by: `${x.action_by ? x.action_by.name : ''} ${x.created_at}`
            }
        })
    }
}

function view_activity_log(logs) {
    if(logs != undefined) {
        return logs.map(x => {
            return {
                description: x.activity_desc,
                by: `${x.action_by ? x.action_by.name : ''} ${common.setDate(x.created_at)}`
            }
        })
    }
}

export function amend_invoices(invoices) {
    if(invoices && invoices.length > 0){
        return invoices.map(x => {
            return {
                quote_ref_no_amend: x.merchant_reference,
                price_vat_inline: `${common.formatCurrency(x.amount)} (${common.formatCurrency(x.vat)})`
            }
        })
    }

    return []
}

export function amend_invoices_without_vat(invoices) {
    if(invoices && invoices.length > 0){
        return invoices.map(x => {
            return {
                quote_ref_no_amend: x.merchant_reference,
                price_vat_inline: common.formatCurrency(x.amount)
            }
        })
    }

    return []
}

export function allDocuments(documents, quote_ref_no, module = 'policy', is_completed = true, types = [], shows:any = null) {
    if(documents && documents.length > 0) {

        // console.log('documents',documents);

        let showDelete = false
        let showDeleteOnUpload = false
        let showLog = true
        let showDownload = true
        if(shows) {
            showDelete = shows.showDelete
            showDeleteOnUpload = shows.showDeleteOnUpload
            showLog = shows.showLog
            showDownload = shows.showDownload
        }
        let quoteRefNo = quote_ref_no
        
        return documents.map(x => {
            let uploaded_by = 'Not found'
            let title = x.title ? x.title : 'Not Selected'
            const encrypted_file_path = x.encrypted_file_path
            uploaded_by = x.created_by_customer ? x.created_by_customer.email : (x.created_by_skye_user ? x.created_by_skye_user.email : '-')
            let uploaded = `${x.created_at}<br />${uploaded_by}`
            if(module == 'amendment') {
                quoteRefNo = x.quote_ref_no
                title = x.type_name
                // encrypted_file_path = x.file_public_path
                uploaded = `${x.updated_at_format}<br />${uploaded_by}`
            }
            return {
                id: x.id,
                uuid: x.uuid,
                policy_id: x.policy_id ? x.policy_id : 0,
                extension: x.file_extension.toLowerCase(),
                file_path: x.file_path,
                file_public_path: x.file_public_path,
                encrypted_file_path,
                title,
                original_filename: x.original_filename,
                quote_ref_no: quoteRefNo,
                uploaded_by: uploaded,
                is_completed,
                showDelete,
                showDeleteOnUpload,
                showLog,
                showDownload,
                module,
                document_type: x.document_type && x.document_type.document_type ? x.document_type.document_type : 'Not Selected',
                type: x.type ? x.type : 0,
                types,
                is_temp: !is_completed,
                created_at: x.created_at,
                created_by_skye_user:x.created_by_skye_user,
                // default_created_at: -moment(x.default_created_at).unix(),
                sort_order: x.document_type  ? x.document_type.sort_order : 0
            }
        }).sort((a, b) => b.policy_id - a.policy_id)
        .sort((a, b) => a.sort_order - b.sort_order)
    }

    return []
}

function setCurrentData(item, isDiscrepancy = false) {
    if(!item) return ''

    if(isDiscrepancy) return `${item.discrepancy_amount} | ${item.discrepancy_underwriter} | ${item.discrepancy_approver}`
    else return`${item.underwriter} | ${item.policy_number} | ${item.chasis_no} | ${item.policy_start_date} | ${item.credit_notes} | ${item.debit_notes} | ${item.policy_sales_agent}`

}

function leadData(lead) {
    return {
        show: true,
        vehicle_details: `${lead.car_year} | ${lead.make} | ${lead.model} | ${lead.model_details}`,
        lead_status: lead.lead_status_text,
        lead_status_id: lead.lead_status_id,
        lead_id: lead.id,
        migrated_lead_id: lead.migrated_lead_id,
        task_status: lead?.latest_task?.disposition_text,
        due_at: lead?.latest_task?.due_date_format,
        request_time_line: lead.created_at,
        duplicate_source: lead.duplicate_source,
        vehile_type: lead.vehicle_type,
        is_end_state_lead: lead.is_end_state_lead,
        lead_source: lead.lead_source,
        time_lines: timelines(lead),
        driver_id: lead?.lead_driver_detail?.id,
        customer_id: lead.customer_id,
        uuid: lead.lead_driver_detail?.uuid,
        items: leadDataArray(lead),
        check_pending_invoice: lead.lead_has_pending_invoice ? true : false,
        check_pending_invoice_main: lead.lead_has_pending_invoice && lead.lead_has_pending_invoice.is_amend === 0 ? true : false,
        ...showButtons(lead)
    }
}

function customerDetailData(lead) {
    let gcc_spec = ''
    let vehicle_use = ''
    let existing_policy = ''
    if(lead.vehicle_type == 2) {
        gcc_spec = 'NA'
        vehicle_use = 'NA'
    }
    else {
        if(!lead.gcc_specification) gcc_spec = '-'
        else gcc_spec = lead.gcc_specification == 1 ? 'Yes' : 'No'

        if(!lead?.lead_driver_detail?.personal_use) vehicle_use = '-'
        else vehicle_use = lead.lead_driver_detail.personal_use == 1 ? 'Personal' : 'Commercial'
    }

    if(!lead.lead_driver_detail?.third_party_liability) existing_policy = '-'
    existing_policy = lead.lead_driver_detail?.third_party_liability == 1 ? 'Yes' : 'No'
    return {
        show: lead.lead_driver_detail && lead.model != 'QL' ? true : false,
        vehile_type: lead.vehicle_type,
        estimated_value: common.formatCurrency(lead?.lead_driver_detail?.car_value),
        is_new: lead.is_new == 1 ? 'Yes' : 'No',
        registration_emirate: lead.registration_emirate_text,
        first_registration_date: lead.first_registration_date_format,
        policy_active: lead?.lead_driver_detail?.current_policy_active == 1 ? 'Yes' : 'No',
        expected_policy: lead.lead_driver_detail && lead.lead_driver_detail.policy_start_date_format ? lead.lead_driver_detail.policy_start_date_format : '-',
        gcc_spec,
        vehicle_use,
        existing_policy
    }
}

function driverDetailsData(lead) {
    let gender = ''
    if(lead.lead_driver_detail) {
        if(lead.lead_driver_detail?.gender == 1) gender = 'fa fa-male text-info'
        else if(lead.lead_driver_detail?.gender == 2) gender = 'fa fa-female text-info'
        else if(lead.lead_driver_detail?.gender == 3) gender = 'icon-svg icon-rather-not-say'
        else gender = ''
    }
    let claims_made = ''
    let no_claims = ''
    let no_claim_certificate = ''
    if(lead.vehicle_type == 2) {
        claims_made = 'NA'
        no_claims = 'NA'
        no_claim_certificate = 'NA'
    }
    else {
        if(!lead.lead_driver_detail?.claimed_insurance) claims_made = '-'
        else claims_made = lead.lead_driver_detail && lead.lead_driver_detail.claimed_insurance == 1 ? 'Yes' : 'No'

        no_claims = lead.lead_driver_detail?.claims && lead.lead_driver_detail?.claimed_insurance == 1 ? lead.lead_driver_detail.claims : '-'

        no_claim_certificate = lead.lead_driver_detail ? lead.lead_driver_detail.no_claim_certificate_text : '-'
    }
    return {
        show: lead.lead_driver_detail && lead.lead_driver_detail.dob ? true : false,
        dob: lead.lead_driver_detail ? lead.lead_driver_detail.dob_format : '-',
        nationality: lead.lead_driver_detail ? lead.lead_driver_detail.nationality_country : '-',
        first_license_country: lead.lead_driver_detail ? lead.lead_driver_detail.first_license_country : '-',
        driving_experience: lead.lead_driver_detail ? lead.lead_driver_detail.driving_experience_text : '-',
        uae_driving_experience: lead.lead_driver_detail ? lead.lead_driver_detail.uae_driving_experience_text : '-',
        driver_name: lead.lead_driver_detail ? lead.lead_driver_detail.driver_name : '-',
        gender,
        claims_made,
        no_claims,
        no_claim_certificate
    }
}

function policyDetailsData(lead) {
    if(lead.latest_policy_purchase) {
        let policy_type = '-'
        if(!lead.latest_policy_purchase.car_insurance_policy) policy_type = '-'
        else {
            if(lead.latest_policy_purchase?.car_insurance_policy.policy_type == 5) policy_type = 'THIRD PARTY'
            else policy_type = 'COMPREHENSIVE'
        }
        return {
            show: lead.latest_policy_purchase ? true : false,
            status_value: lead.latest_policy_purchase.status_text,
            active_status_value: lead.latest_policy_purchase.policy_active_status == true ? 'Active' : 'In-Active',
            policy_expiry_date: lead.latest_policy_purchase.policy_end_date ? lead.latest_policy_purchase.policy_end_date_format : '-',
            quote_no: lead.latest_policy_purchase.car_lead_quote ? lead.latest_policy_purchase.car_lead_quote.quote_ref_no : '-',
            policy_name: lead.latest_policy_purchase?.car_insurance_policy?.policy_name,
            policy_type,
            premium: common.formatCurrency(lead.latest_policy_purchase.policy_price_amount),
            registration_emirate: lead.registration_emirate_text,
            car_value: common.formatCurrency(lead.lead_driver_detail?.car_value),
            chassis_no: lead.latest_policy_purchase.chassis_number ? lead.latest_policy_purchase.chassis_number : '-',
            transaction_type: lead.latest_policy_purchase.transaction_type_text ? lead.latest_policy_purchase.transaction_type_text : '-',
            policy_sa: lead.latest_policy_purchase.policy_sales_agent ? lead.latest_policy_purchase.policy_sales_agent.name : '-',
            underwriter: lead.latest_policy_purchase.policy_underwriter ? lead.latest_policy_purchase.policy_underwriter.name : '-',
            policy_issued_on: lead.latest_policy_purchase.issue_date ? lead.latest_policy_purchase.issue_date : '-',
            vehile_type: lead.vehicle_type,
            documents: allDocuments(lead.latest_policy_purchase?.car_lead_documents, lead.latest_policy_purchase.car_lead_quote.quote_ref_no)
        }
    }

    return {
        show: false
    }
}

function timelines(lead) {
    return {
        current_visited_date: lead.current_visited_date_format,
        last_visited_date: lead.previous_visited_date_format,
        created_at: lead.created_at
    }
}

function getResponseObject(lead_status_id, with_policy) {
    if(lead_status_id == 4) {
        return 'deal_statuses'
    }
    else if(lead_status_id == 12) {
        return 'cancel_statuses'
    }
    else {
            if(with_policy) {
                return 'policy_task_statuses'
            }
            else {
                return 'statuses'
            }
    }
}

function leadDataArray(lead) {
    return [{
        vehicle_details:`${lead.car_year} | ${lead.make} | ${lead.model} | ${lead.model_details}`,
        lead_status: lead.lead_status_text,
        lead_status_id: lead.lead_status_id,
        task_status: lead?.latest_task?.disposition_text,
        request_time_line: lead.created_at,
        due_at: lead?.latest_task?.due_date_format,
        duplicate_source: lead.duplicate_source,
        lead_source: lead.lead_source,
        time_lines: timelines(lead),
    }]
}

function showButtons(lead) {
    const { lead_status_id, lead_driver_detail, manufacturer_id, is_end_state_lead, vehicle_type, make, model, is_show_make_invoice, latest_policy_purchase, lead_has_pending_invoice } = lead
    
    let showNewDriver = [13, 9].includes(lead_status_id)
    let showNewCar = lead_status_id === 14
    let showAddDetails = false
    const showViewTasks = true
    let showEditLeadDetails = false
    let showAddLeadDetails = false
    let showGenerateQuotePdf = false
    let showUploadDocuments = false
    let showCompareQuotes = false
    let showSendQuote = false
    let showMakeInvoice = false

    if(lead_driver_detail && manufacturer_id != 66 && lead_driver_detail.dob !== null && lead_driver_detail.dob !== '' && vehicle_type == 1) {
        if(!is_end_state_lead) showNewDriver = true
        if([12, 13, 9].includes(lead_status_id)) showNewDriver = false
        showNewCar = true
    }
    if(make == 'QL' && model == 'QL' && !is_end_state_lead) showAddDetails = true

    if( manufacturer_id != 66) showEditLeadDetails = true
    if(lead_driver_detail && manufacturer_id != 66) {
        if(lead_driver_detail.dob !== null && lead_driver_detail.dob !== '') {
            showCompareQuotes = true

            if(![4, 9, 11, 12, 13, 14, 15].includes(lead_status_id) || is_show_make_invoice) {
                showSendQuote = true
                if(![9, 10, 2].includes(lead_status_id)) showGenerateQuotePdf = true
                if(!lead_has_pending_invoice) showMakeInvoice = true
            }
        }
    }

    // BND-2271 : new condition added Policy completed and inactive show upload documents
    if((is_show_make_invoice || (!latest_policy_purchase ||  (!latest_policy_purchase.is_active && [10,7,8,9,4,11].includes(latest_policy_purchase?.status)))) && ![2, 9, 10].includes(lead_status_id)) showUploadDocuments = true

    if(!lead_driver_detail || manufacturer_id == 66) 
        showUploadDocuments = false

    // if((is_show_make_invoice || (!latest_policy_purchase || ![10,7,8,9,4,11].includes(latest_policy_purchase?.status) || (!latest_policy_purchase.is_active && [10,7,8,9,4,11].includes(latest_policy_purchase?.status)))) && ![2, 9, 10].includes(lead_status_id)) showUploadDocuments = true
    // if((is_show_make_invoice || (!latest_policy_purchase || ![10,7,8,9,4,11].includes(latest_policy_purchase?.status))) && ![2, 9, 10].includes(lead_status_id)) showUploadDocuments = true

    // if(showMakeInvoice) showUploadDocuments = true

    if(!lead_driver_detail?.dob && manufacturer_id == 66) showAddLeadDetails = true

    if(lead.upload_docs_pending)
        showMakeInvoice = false

    if([7, 11, 15].includes(role_id)) {
        showMakeInvoice = false;
        showSendQuote = false;
        showUploadDocuments = false;
        showGenerateQuotePdf = false;
        showEditLeadDetails = false;
        if(role_id != 15) {
            showCompareQuotes = false;
            
        }
    }

    // console.log('showMakeInvoice',lead.upload_docs_pending)
    return { 
        showNewDriver, 
        showNewCar, 
        showAddDetails, 
        showViewTasks, 
        showEditLeadDetails, 
        showAddLeadDetails,
        showGenerateQuotePdf,
        showUploadDocuments,
        showCompareQuotes,
        showSendQuote,
        showMakeInvoice
     }
}

function setANT(data, amend_type) {
    return {
        amend_type,
        amount: data.amount || 0,
        car_value: data.car_value || 0,
        claimed_insurance: data.claimed_insurance || 2,
        claims: data.claims || null,
        current_policy_active: data.current_policy_active || 1,
        dob: data.dob || '',
        driver_name: data.driver_name || '',
        driving_experience: data.driving_experience || 4,
        email: data.email,
        existing_policy_expired: data.existing_policy_expired || 2,
        expiry_date_time: data.expiry_date_time || '',
        first_driving_license_country: data.first_driving_license_country || 0,
        fully_comprehensive: data.fully_comprehensive || 1,
        gender: data.gender || 0,
        insurance_payment: data.insurance_payment || 0,
        invoice_doc: data.invoice_doc || null,
        invoice_id: data.invoice_id,
        invoice_number: data.parent_merchant_reference,
        is_amend: 1,
        language: data.language || 'en',
        merchant_reference: data.merchant_reference,
        name: data.name,
        nationality: data.nationality || 0,
        new_email: data.new_email || '',
        new_name: data.new_name || '',
        new_phone_country_code: data.new_phone_country_code || '+971',
        new_phone_number: data.new_phone_number || '',
        no_claim_certificate: data.no_claim_certificate || 1,
        order_description: data.order_description || '',
        original_price: data.original_price || '',
        payment_date: data.payment_date || '',
        payment_type: data.payment_type || 0,
        personal_use: data.personal_use || 1,
        phone_number: data.phone_number,
        policy_end_date: data.policy_end_date || '',
        policy_sales_agent: data.policy_sales_agent,
        policy_sales_agent_id: data.policy_sales_agent_id,
        policy_start_date: data.policy_start_date || moment().format('YYYY-MM-DD'),
        reference_no: data.reference_no || '',
        third_party_liability: data.third_party_liability || 2,
        uae_driving_experience: data.uae_driving_experience || 4
    }
}

function setNotNT(data, amend_type) {
    return {
        amend_type,
        amount: data.amount || 0,
        email: data.email,
        expiry_date_time: data.expiry_date_time || '',
        insurance_payment: data.insurance_payment || 0,
        invoice_doc: data.invoice_doc || null,
        invoice_id: data.invoice_id,
        invoice_number: data.parent_merchant_reference,
        is_amend: 1,
        language: data.language || 'en',
        merchant_reference: data.merchant_reference,
        name: data.name,
        order_description: data.order_description || '',
        original_price: data.original_price || '',
        payment_date: data.payment_date || '',
        payment_type: data.payment_type || 0,
        phone_number: data.phone_number,
        existing_policy_end_date: data.policy_end_date || '',
        policy_end_date: '',
        policy_sales_agent: data.policy_sales_agent,
        policy_sales_agent_id: data.policy_sales_agent_id,
        reference_no: data.reference_no || ''
    }
}

export function openLeadDetails(lead){
    const result = {
        lead_status: lead.lead_status_text,
        is_end_state_lead: lead.is_end_state_lead,
        lead_status_id: lead.lead_status_id,
        highlight: lead.latest_task ? lead.latest_task.is_highlight_task : false,
        lead_id: lead.id,
        is_show_closed_lead: lead.is_show_closed_lead,
        is_show_closed_lead_from_llr_to_closed: lead.is_show_closed_lead_from_llr_to_closed,
        is_show_make_invoice: lead.is_show_make_invoice,
        agent: lead.agent,
        name: `${lead.car_year} | ${lead.make} | ${lead.model} | ${lead.model_details}`,
        customer_id: lead.customer_id,
        policy_expiry: lead.latest_policy_purchase && lead.latest_policy_purchase?.policy_active_status == true ? lead.latest_policy_purchase?.policy_end_date_format : '-',
        task_due: !lead.is_end_state_lead && lead?.latest_task?.status == 1 ? lead.latest_task?.due_date_format : 'No Task',
        lead: leadData(lead),
        customer_detail: customerDetailData(lead),
        driver_details: driverDetailsData(lead),
        policy_details: policyDetailsData(lead),
        with_policy: lead.latest_policy_purchase ? true : false,
        vehicle_type: lead.vehicle_type,
        hot_lead: lead.hot_lead === 1 ? true : false,
        hot_renewal_lead: lead.hot_renewal_lead === 1 ? true : false,
    }
    return result
}
